.preloaderArea {
  position: fixed;
  background: #fff;
  z-index: 11000;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.spinner {
  margin: auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  position: absolute;
  left: 50%;
  right: 50%;
  margin-left: -20px;
  top: 50%;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #623bcc;
  border-radius: 100%;
  -webkit-animation: sk-bounce 1s infinite ease-in-out;
  animation: sk-bounce 3s infinite ease-in-out;
}

.dot1 {
  background-color: #f6bb67;
}

.dot2 {
  top: auto;
  background-color: #333841;
  bottom: 0;
  -webkit-animation-delay: -2s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(180deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  50% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
