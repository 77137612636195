.spinner_inine {
  --spinner-item-size: 10px;
  --spinner-border: white;
}

.spinner__items {
  width: 30px;
  height: 26px;
  position: relative;
  animation: parent-spin 1500ms cubic-bezier(0.19, 0.99, 0.89, 0.29) infinite;
}

@media (prefers-reduced-motion: reduce) {
  .spinner__items {
    animation: none;
  }
}

.spinner__item {
  display: block;
  width: var(--spinner-item-size);
  height: var(--spinner-item-size);
  /* border: 2px solid var(--spinner-border); */
  border-radius: 20px;
  position: absolute;
}

.spinner__item:nth-child(1) {
  background: rgb(245, 7, 92);
  top: 0;
  left: 50%;
  margin-left: calc((var(--spinner-item-size) / 2) * -1);
}

.spinner__item:nth-child(2) {
  background: rgb(63, 81, 181);
  bottom: 0;
  right: 0;
}

.spinner__item:nth-child(3) {
  background: white;
  bottom: 0;
  left: 0;
}

@keyframes parent-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.visually-hidden {
  display: block;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(1px);
  white-space: nowrap;
  position: absolute;
}
