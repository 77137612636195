.post {
  display: flex;
  justify-content: space-between;
}

.post .avatar {
  width: 180px;
  height: 36px;
  background-color: #ccc;
  border-radius: 16px;
  margin: 8px;
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(229, 229, 229, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-avatar 2s infinite ease-out;
}
.post .line {
  width: 36px;
  height: 31px;
  margin-top: 12px;
  border-radius: 16px;
  margin-left: 50px;
  background-image: linear-gradient(
    90deg,
    #f4f4f4 0px,
    rgba(229, 229, 229, 0.8) 40px,
    #f4f4f4 80px
  );
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
}
.post .avatar + .line {
  margin-top: 11px;
}
.post .line ~ .line {
  background-color: #ddd;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}
@keyframes shine-avatar {
  0% {
    background-position: -64px;
  }
  40%,
  100% {
    background-position: 176px;
  }
}
